.hidden-text-one-container {
  padding: 20px;
}

.language-measure-one {
  padding-bottom: 50px;
  background-color: #eee8d5;
}

.language-measure-three {
  height: calc(100% - 80px);
}

.language-measure-three .press-to-continue {
  width: 75%;
  margin-top: 10px;
}

.language-main-text-one {
  font-family: monospace;
  padding: 60px 20px 10px;
  font-size: 12pt;
}

.language-quote-container {
  box-sizing: border-box;
  min-height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.language-quote-container-marquee-spaced {
  padding: 50px;
  padding-bottom: 0;
}

.language-quote-container-spanish {
  background-color: #eee8d5;
}

.language-quote-container-subtitle .language-quote {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  color: white;
  margin-top: 100%;
  padding: 10px;
}

.language-quote {
  font-family: Lora, serif;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
}

.language-quote .press-to-continue {
  margin-top: 20px;
  width: 100%;
}

.language-quote-container-spanish .language-quote {
  font-family: monospace;
  font-size: 12pt;
}

.marquee {
  position: fixed;
  display: flex;
  align-items: center;
  max-height: 40px;
  padding: 0 10px;
  width: 100%;
  left: 0;
  overflow: hidden;
  font-family: Lora;
  background-color: #fdf6e3;
}

.marquee-left {
  right: 0;
  bottom: -40px;
  transform: rotate(-90deg);
  transform-origin: top left;
  width: 100vh;
}

.marquee-right {
  left: auto;
  right: 0;
  top: -40px;
  transform: rotate(-90deg);
  transform-origin: bottom right;
  width: 100vh;
}

.marquee > p {
  height: 100%;
  white-space: nowrap;
  transform: translateX(100vw);
  transition: 60s linear transform;
}

.marquee-left > p,
.marquee-right > p {
  transform: translateX(105vh);
}

.marquee-animating > p {
  transform: translateX(-105%);
}

.marquee-top {
  top: 0;
}

.marquee-bottom {
  bottom: 0;
}
