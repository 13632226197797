.breath-container {
  height: calc(100% - 100px);
  min-height: 100%;
}

.accent {
  background-color: #eee8d5;
  font-family: monospace;
  font-size: 12pt;
  padding: 10px;
}

.video-shrunk {
  transition: 0.3s ease all;
  width: 100px;
  height: 200px;
}

.camera-view {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;
}

.video-call {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.video-call-overlay {
  transition: 0.3s ease all;
  transition-delay: 0.2s;
  padding: 30px;
  margin-bottom: 10px;
  font-size: 14pt;
  transform: translateY(120%);
  opacity: 0;
  background-color: #fdf6e3;
}

.video-call-overlay-show {
  transform: translateY(0);
  opacity: 1;
}

.final {
  padding: 20px;
}
