.App {
  font-family: Lora, serif;
  height: 100%;
  overflow-y: scroll;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 20pt;
  height: calc(100% - 50px);
  box-sizing: border-box;
}

.next-btn {
  position: fixed;
  width: 100%;
  height: 50px;
  bottom: 0;
  left: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
