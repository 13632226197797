.video-container {
  overflow: hidden;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.video-overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.video::cue {
  font-family: Lora, serif;
  background-color: rgba(0, 0, 0, 0.7);
  text-shadow: 0 3px 10px black;
  color: white;
  width: 90%;
  margin-bottom: 60px;
  padding: 10px;
  box-sizing: border-box;
}

.force-playback {
  font-size: 24pt;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
