.pull-to-continue-container {
  font-size: 12pt;
  text-align: center;
  opacity: 0.2;
  transition: 0.1s ease all;
  position: relative;
  overflow: hidden;
  height: 50px;
}

.press-to-continue {
  font-family: Lora;
  font-size: 12pt;
  background: none;
  border: 1px solid black;
  padding: 10px 20px;
  border-radius: 3px;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 50px;
}

.pull-to-continue-container .pull-background {
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.75);
  background-color: #fdf6e3;
  width: 100%;
  height: 10px;
  position: absolute;
  top: 0;
  transform: translateY(50px);
}
