.outro-container {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
}

.credits-container {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  margin: 10px;
  padding: 20px;
  overflow: hidden;
  flex: 1;
}

.credits-container .roll {
  transform: translateY(50%);
  animation: creditRoll 30s linear forwards;
  animation-fill-mode: forwards;
}

.fundraise-container {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 11pt;
  margin: 10px;
  margin-top: 0;
  padding: 5px 20px;
}

.donate {
  display: block;
  font-family: Lora;
  font-size: 11pt;
  margin: 20px 0;
  z-index: 2;
  background-color: #dbded9;
  border: 1px solid black;
  border-radius: 3px;
  text-decoration: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
}

@keyframes creditRoll {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(calc(-100% + 72px));
  }
}

.credits-container b {
  display: block;
}
